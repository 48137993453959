@import "~@ui/styles/tools";

.block {
    background: $color-off-white;
    border-radius: 24px;

    cursor: pointer;

    transition: background 0.2s ease-in-out;

     &:hover {
        background: $color-input-background;
     }

    &--skeleton {
        border-radius: 24px;
        width: 100%;
        height: 92px;
    }
}

.list--skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.block + .block {
    margin-top: 10px;
}

.header {
    display: flex;
    flex-direction: row;
    padding: 28px;
    align-items: center;
}

.title {
    flex: 1;
    font-size: 24px;
    line-height: 110%;
    font-weight: 500;
    color: $color-title-active;
}

.body {
    padding: 0 28px 28px;
}

.description {
    margin-bottom: 0;

    p:last-child {
        margin-bottom: 0;
    }
}

.icon-container {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;

    &--rotated {
        transform: rotate(45deg);
    }
}

@include media-mobile {
    .header {
        padding: 20px;
    }

    .title {
        font-size: 20px;
    }

    .body {
        padding: 0px 20px 20px;
    }
}