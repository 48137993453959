@import "~@ui/styles/tools";
@import "../../styles/variables";

.container {
    padding: 24px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    user-select: none;

    p {
        padding: 0;
        margin: 0;
    }
}

.top-block {
    display: flex;
    flex-direction: column;

    .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        color: $color-gray;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .full {
        -webkit-line-clamp: unset !important;
    }
}

.full-container {
    height: unset;
}

.first-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    gap: 10px;
}

.image-block {
    display: flex;
    gap: 20px;
    align-items: center;
    flex: 1;

    .image-circle {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        background: $color-grey;
    }
}

.name-block {
    flex: 1;
}

.title {
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
    color: $color-title;
    text-transform: uppercase;
}

.name {
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    color: $color-primary;
}

.bottom-block {
    display: flex;
    justify-content: space-between;
}

.more {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: $color-primary;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}

.date {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: $color-gray;
}

@include media-tablet {
    .title {
        font-size: 20px;
        line-height: 26px;
    }

    .name {
        font-size: 14px;
        line-height: 18px;
    }
}

@include media-mobile {
    .container {
        padding: 20px;
        height: 270px;
    }

    .text {
        font-size: 14px;
        line-height: 25px;
        -webkit-line-clamp: 3;
    }

    .image-block {
        gap: 15px;
    }

    .title {
        font-size: 16px;
        line-height: 19px;
    }

    .name {
        font-size: 13px;
        line-height: 22px;
    }

    .more {
        font-size: 14px;
        line-height: 16px;
    }

    .date {
        font-size: 14px;
        line-height: 24px;
    }
}